import React, { useState, useEffect } from "react";
import axios from "axios";
import SuccessToast from "./SuccessToast";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';



function Profilesidebar() {
    const [selectedItem, setSelectedItem] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [cards, setCards] = useState([]);
    const [cards1, setCards1] = useState([]);
    const [cards2, setCards2] = useState([]);
    const [address_detail, setAddress] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const navigate = useNavigate();
    const { translate } = useTranslation();
    const [location, setLocation] = useState(null);


    //add address values

const [addressType1, setAddressType] = useState('');
const [district1, setDistrict] = useState('');
const [floorNo1, setFloorNo] = useState('');
const [state1, setState] = useState('');
const [building1, setBuilding] = useState('');
const [country1, setCountry] = useState('');
const [city1, setCity] = useState('');
const [postalCode1, setPostalCode] = useState('');
const [add_id, setAdd_id] = useState('');
const [gender, setGender] = useState('');


const handleGenderChange = (e) => {
    setGender(e.target.value);
};

    

   

    
   
   

    const my_orders = () => {
        navigate("/Myorders", { replace: true });
    }

    const profile_edit = () => {
        setIsEditing(true);
        setIsEditing1(true);
    };

    const profile_update = async () => {
        setIsEditing1(false);
        
     
        const first_name = cards.firstname;
        const last_name = cards.lastname;
        const phone_number = cards.phone_number;
        const email = cards.email;
        // const gender = cards.gender;
        // const password = cards.password;
        const user_id = localStorage.getItem('admin_id');
        const api_url = localStorage.getItem('api_url');


        const response = await axios.post(api_url + '/updateuserdetails', {
            user_id,
            first_name,
            last_name,
            phone_number,
            email,
            gender: gender,
            // password
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            transformRequest: [(data, headers) => {
              const params = new URLSearchParams();
              for (const key in data) {
                params.append(key, data[key]);
              }
              return params.toString();
            }],
          });
    
          const check_res = response.data.status;

          if(check_res){
            setIsEditing(false);
            // 
           alert("Success! Your profile has been updated.");
           window.location.reload();
        }
       
    };



    const handleItemClick = (index) => {
        setSelectedItem(index);
    };


    //   setGender(cards.gender);
        //  gender = cards.gender;c
    useEffect(() => {
        const fetchCards = async () => {
          try {
            const user_id = localStorage.getItem('admin_id');
            const api_url = localStorage.getItem('api_url');
            const response = await axios.get(api_url + '/getProfile/'+user_id);
            setCards(response.data.profile);
            setCards1(response.data);
            setCards2(response.data.notification);
            // setAddress(response.data.getaddress);
            setGender(response.data.profile.usergender);

           //get address
           

          } catch (error) {
            console.error("Error fetching card data:", error);
          }
        };
    
        fetchCards();
      }, []);
    
  

    return (
     
 <div  className='full_profile1'>      


    <div className='profile_detail'>
        
    <div className={selectedItem === 0 ? 'personalinformation' : 'personalinformation hide'}>
                    

    <div className='form-group fir_name'>
    <img className={isEditing1 ? 'add_edit1 hide' : 'add_edit1'} onClick={profile_edit} title="edit" src={require('./img/edit_add.png')} />
    <img className={isEditing1 ? 'add_edit1' : 'add_edit1 hide'} onClick={profile_update} title="update" src={require('./img/update.png')} />
            <label className='lable_name'><b>{translate('personal_information')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='text' className='f_name' name="first_name" id="first_name" placeholder='First Name' value={cards.firstname} onChange={(e) => setCards({ ...cards, firstname: e.target.value })}/>
                    <input disabled={!isEditing} type='text' className='l_name' name="last_name" id="last_name" placeholder='Last Name' value={cards.lastname} onChange={(e) => setCards({ ...cards, lastname: e.target.value })}/>

                </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('your_gender')}</b> </label>
            <p></p>
            <div className='name_full_last'>
                <label> <input disabled={!isEditing} type="radio" className='gender_male' value="male" checked={gender === "male"} onChange={handleGenderChange} /> Male </label>
                <label> <input disabled={!isEditing} type="radio" className='gender_female'  value="female" checked={gender === "female"} onChange={handleGenderChange} /> Female </label>
            </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('email_address')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='email' className='f_name' name="email" id="email" placeholder='Enter Email Id' value={cards.email} onChange={(e) => setCards({ ...cards, email: e.target.value })}/>

                </div>
            </div>
            <br></br>
            <div className='form-group fir_name'>
            <label className='lable_name'><b>{translate('mobile_number')}</b> </label>
            <p></p>
                <div className='name_full_last'>
                    <input disabled={!isEditing} type='text' className='f_name' name="mobile" id="mobile" placeholder='Enter Mobile Number' value={cards.phone_number} onChange={(e) => setCards({ ...cards, phone_number: e.target.value })}/>

                </div>
            </div>
            <br></br>

                </div>
          
        
    </div>
  </div>
  
       
    );
}

export default Profilesidebar;
