import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import "./Catslide.css"; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const Catslide2 = () => {
  const [cards, setCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Declare searchQuery state
  const [startIndex, setStartIndex] = useState(0); // State for pagination

  const navigate = useNavigate();

  const handleDivClick = (id, type) => {
    localStorage.setItem("event_id", id);
    localStorage.setItem("event_type", type);

    navigate("/Events", { replace: true });
  };

  const api_url = localStorage.getItem('api_url');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(api_url + '/getSports');
        setCards(response.data.sports);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, [api_url]);

  const filteredCards = cards.filter((card) =>
    card.event_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const visibleCards = filteredCards.slice(startIndex, startIndex + 5);

  return (
    <div className="">
      <div className="search-filter-container">
        <input
          type="text"
          placeholder="Search Sports"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
      </div>

      <div className="car_d row">
        {visibleCards.map((card) => (
          <div key={card.id} className="col-md-2" style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            height: "240px",
            margin: "10px",
            padding: "20px",
          }} onClick={() => handleDivClick(card.id, card.type)}>
            <img className='med_img' src={card.event_image} alt={card.event_name} />
            <hr />
            <div className="medicine-details">
              <p>{card.event_name}</p>
            </div>
          </div>
        ))}
      </div>

     
    </div>
  );
};

export default Catslide2;
