import React, { useState, useEffect } from 'react';
import "./style.css";
import "./Product_detail.css";
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Showtimes from './Showtimes';
import { useTranslation } from './TranslationContext';
import { useNavigate } from 'react-router-dom';


const Detail_page = () => {
  const [movieDetails, setMovieDetails] = useState(null);
  const [castDetails, setCastDetails] = useState([]);
  const [crewDetails, setCrewDetails] = useState([]);
  const { translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedLanguage, setSelectedLanguage] = useState('');
const [selectedMovieType, setSelectedMovieType] = useState('');
const [selectedFormat, setSelectedFormat] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const movie_id = localStorage.getItem('movie_id');
        
        // Fetch movie details
        const movieResponse = await axios.get(`${api_url}/getmovie_details/${movie_id}`);
        setMovieDetails(movieResponse.data.movie_details);


      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };

    fetchMovieDetails();
  }, []);

  if (!movieDetails) {
    return <div>{translate('Loading...')}</div>;
  }


 

  return (
    <div className="box">
      <Header_home />

      <div className="movie-detail-page">
      {/* Movie Banner */}
      <div className="movie-banner" style={{ backgroundImage: `url(${movieDetails.movie_image})` }}>
        <div className="movie-details-container">
          {/* Movie Image (Left) */}
          <div className="movie-image">
            <img src={movieDetails.movie_image} alt={movieDetails.movie_name} />
          </div>

          {/* Movie Information (Right) */}
          <div className="movie-info">
            <h2>{movieDetails.movie_name}</h2>
            <div className="movie-rating">
              <span>{translate('Rating')}: {movieDetails.ratings} / 10</span>
              <div className="stars">
                {'⭐'.repeat(Math.round(movieDetails.ratings))}
              </div>
            </div>
            <p style={{textAlign:"left"}}> {movieDetails.movie_type}</p>
            <p style={{textAlign:"left"}}> {movieDetails.category}</p>
            <p style={{textAlign:"left"}}> {movieDetails.language}</p>
            <p style={{textAlign:"left"}}> {movieDetails.timing} | {new Date(movieDetails.release_date).toDateString()}</p>
          </div>
        </div>
      </div>

    </div>

      <Showtimes />
        
      <Footer />
    </div>
  );
};

export default Detail_page;
