import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "./Product_detail1.css";  // Add styles for the uploaded image design
import { useTranslation } from './TranslationContext';

const Product_detail1 = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const { translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const event_id = localStorage.getItem('event_id');

        const event_type = localStorage.getItem('event_type');

        
        // Fetch movie details
        if(event_type ==="event"){
          const movieResponse = await axios.get(`${api_url}/getEventdetail/${event_id}`);
          setEventDetails(movieResponse.data.event);
        }else{
          const movieResponse = await axios.get(`${api_url}/getsportdetail/${event_id}`);
          setEventDetails(movieResponse.data.sport);
        }
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventDetails();
  }, []);

  if (!eventDetails) {
    return <div>{translate('Loading...')}</div>;
  }

  const handleDivClick = (id) => {
    localStorage.setItem("hall_id", id);
    navigate("/Seatseaction", { replace: true });
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  };

  const handleBookTickets = () => {
    navigate("/Detail_page", { replace: true });
  };
  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="event-detail-page">
      {/* Event Banner Section */}
      <div
        className="event-banner"
        style={{ backgroundImage: `url(${eventDetails.event_image})` }}
      >
       
      </div>
      <div className="event-banner-content">
        <div style={{display:"flex"}}>
        <div>
        <h3 className="event-title">{eventDetails.event_organiser} - {eventDetails.event_name}</h3>
          <p className="event-date-time">
          {capitalizeFirstLetter(eventDetails.type)} | {eventDetails.hours}
          </p>
        </div>
        <div style={{marginLeft:"10%"}}>
        <button className="book-tickets" onClick={() => handleDivClick(eventDetails.hall_details._id)}>
          {translate('Book')}
        </button>
        </div>
        </div>
        
          
          <hr></hr>
          <p className="event-location"> {formatDate(eventDetails.event_date)} - {eventDetails.hall_details.place} {eventDetails.hall_details.address_detail}</p>
        </div>
      {/* Event Information Section */}
      <div className="event-info-section">
        <div className="event-details">
          <p><b>{translate('Event Organizer')}:</b> {eventDetails.event_organiser}</p>
          <p><b>{translate('Hours')}:</b> {eventDetails.hours}</p>
          <p><b>{translate('Location')}:</b> {eventDetails.hall_details.place}</p>
          <p><b>{translate('Ticket Price')}:</b> ₹{eventDetails.hall_details.chair_price} onwards</p>
        </div>
        <div className="event-description">
          <h3>{translate('About the event')}</h3>
          <p>{eventDetails.about}</p>
        </div>

        {/* Event Hall Image and Location */}
        <div className="event-details">

          <p>{eventDetails.hall_details.address_detail}</p>
          <iframe
            src={`https://www.google.com/maps?q=${eventDetails.hall_details.lat},${eventDetails.hall_details.long}&output=embed`}
            width="300"
            height="200"
            allowFullScreen=""
            loading="lazy"
            title="Event Location Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Product_detail1;
