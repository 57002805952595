import React, { useState } from 'react';
import './HelpSupport.css'; // Import the CSS file

const HelpSupport = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here (e.g., sending data to backend)
        console.log('Form submitted:', formData);
    };

    return (
        <div className="help-support-container">
            <h2>Help & Support</h2>

            {/* FAQ Section */}
            <div className="faq-section">
                <h3>Frequently Asked Questions</h3>
                <div className="faq-item">
                    <h4>How do I book a movie ticket?</h4>
                    <p>You can book a movie ticket by selecting the movie, choosing your seat, and making a payment online.</p>
                </div>
                <div className="faq-item">
                    <h4>How can I cancel my booking?</h4>
                    <p>Visit the "My Orders" page, find your booking, and click on the "Cancel Booking" option.</p>
                </div>
                <div className="faq-item">
                    <h4>What payment methods are supported?</h4>
                    <p>We support credit/debit cards, UPI, net banking, and digital wallets like PayPal and Google Pay.</p>
                </div>
            </div>

            {/* Contact Form Section */}
            <div className="contact-form-section">
                <h3>Contact Us</h3>
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name">Your Name</label>
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={formData.name} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea 
                            id="message" 
                            name="message" 
                            rows="5" 
                            value={formData.message} 
                            onChange={handleChange} 
                            required 
                        />
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default HelpSupport;
