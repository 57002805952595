// src/components/Header_home.js
import React, { useState } from 'react';
import './style.css';
import Autocomplete from './Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';
import LocationPopup from './LocationPopup'; // Import the LocationPopup component
import RightSideMenu from './RightSideMenu'; // Import the RightSideMenu component

const Header_home = () => {
    const [isLocationPopupOpen, setIsLocationPopupOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('Coimbatore');
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for right-side menu
    const navigate = useNavigate();
    const { translate, setLanguage } = useTranslation();

    const handleLocationClick = () => {
        setIsLocationPopupOpen(true);
    };

    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
        setIsLocationPopupOpen(false);
    };

    const handleDivClick = () => {
        navigate('/Home');
    };
    const handleDivClick2 = () => {
        navigate('/Movieslist');
    };
    const handleDivClick1 = () => {
        localStorage.setItem("type_event", "event");
        navigate('/Eventslist');
    };
    const handleDivClick3 = () => {
        localStorage.setItem("type_event", "sport");
        navigate('/Eventslistsport');
    };

    const handlelogout = () => {
        localStorage.clear();
        navigate('/Login');
    };

    const handleprofile = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="header">
            <div className="row">
                <div className='col-md-3'>
                    <img
                        className=""
                        alt="Logo"
                        title="Logo"
                        src={require('./img/LogoT.png')}
                        onClick={handleDivClick}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick2}>Movies</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick1}>Events</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick3}>Sports</p>
                </div>
                <div className='col-md-1' >
                    <select
                        className='country_name'
                        onClick={handleLocationClick}
                        value={selectedLocation}
                        readOnly
                        style={{ cursor: 'pointer' }}
                    >
                        <option>{selectedLocation}</option>
                    </select>
                </div>
                <div className='col-md-1'>
                    <img
                        className="vector1"
                        alt="Profile"
                        title="Profile"
                        onClick={handleprofile}
                        src={require('./img/Vector1.png')}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className='col-md-1'>
                    <img
                        className="logout"
                        alt="Logout"
                        title="Logout"
                        src={require('./img/logout.png')}
                        onClick={handlelogout}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
            <LocationPopup
                isOpen={isLocationPopupOpen}
                onClose={() => setIsLocationPopupOpen(false)}
                onSelectLocation={handleLocationSelect}
            />
            <RightSideMenu isOpen={isMenuOpen} onClose={closeMenu} />
        </div>
    );
};

export default Header_home;
