import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import "./Product_detail.css";
import { useTranslation } from './TranslationContext';

const Product_detail = () => {
  const [movieDetails, setMovieDetails] = useState(null);
  const [castDetails, setCastDetails] = useState([]);
  const [crewDetails, setCrewDetails] = useState([]);
  const { translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedLanguage, setSelectedLanguage] = useState('');
const [selectedMovieType, setSelectedMovieType] = useState('');
const [selectedFormat, setSelectedFormat] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const movie_id = localStorage.getItem('movie_id');
        
        // Fetch movie details
        const movieResponse = await axios.get(`${api_url}/getmovie_details/${movie_id}`);
        setMovieDetails(movieResponse.data.movie_details);

        // Fetch cast details
        const castResponse = await axios.get(`${api_url}/getCast/${movie_id}`);
        setCastDetails(castResponse.data.casts);

        // Fetch crew details
        const crewResponse = await axios.get(`${api_url}/getCrew/${movie_id}`);
        setCrewDetails(crewResponse.data.crew);

      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };

    fetchMovieDetails();
  }, []);

  if (!movieDetails) {
    return <div>{translate('Loading...')}</div>;
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleBookTickets = () => {
  
    navigate("/Detail_page", { replace: true });
  };

  const handleFormatClick = (format) => {
    setSelectedFormat(format);
  };

  return (
    <div className="movie-detail-page">
      {/* Movie Banner */}
      <div className="movie-banner" style={{ backgroundImage: `url(${movieDetails.movie_image})` }}>
        <div className="movie-details-container">
          {/* Movie Image (Left) */}
          <div className="movie-image">
            <img src={movieDetails.movie_image} alt={movieDetails.movie_name} />
          </div>

          {/* Movie Information (Right) */}
          <div className="movie-info">
            <h2>{movieDetails.movie_name}</h2>
            <div className="movie-rating">
              <span>{translate('Rating')}: {movieDetails.ratings} / 10</span>
              <div className="stars">
                {'⭐'.repeat(Math.round(movieDetails.ratings))}
              </div>
            </div>
            <p style={{textAlign:"left"}}> {movieDetails.movie_type}</p>
            <p style={{textAlign:"left"}}> {movieDetails.category}</p>
            <p style={{textAlign:"left"}}> {movieDetails.language}</p>
            <p style={{textAlign:"left"}}> {movieDetails.timing} | {new Date(movieDetails.release_date).toDateString()}</p>
            
            {/* Book Tickets Button */}
            <button className="book-tickets" onClick={openModal}>
              {translate('Book Tickets')}
            </button>
          </div>
        </div>
      </div>

      {/* About the Movie */} 
      <div className="movie-description">
        <h3><b>{translate('About the movie')}</b></h3>
        <p style={{textAlign:"left"}}>{movieDetails.description}</p>
      </div>

      {/* YouTube Trailer */}
      <div className="movie-trailer">
        <h3><b>{translate('Watch Trailer')}</b></h3>
        <iframe
          width="560"
          height="315"
          src={movieDetails.youtube_link.replace("watch?v=", "embed/")}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* Movie Cast */}
      <div className="movie-cast">
        <h3><b>{translate('Cast')}</b></h3>
        <div className="cast-list">
          {castDetails.length > 0 ? (
            castDetails.map(cast => (
              <div key={cast.id} className="cast-item">
                <img src={cast.cast_image} alt={cast.cast_name} />
                <p>{cast.cast_name}</p>
                <p style={{fontSize:"10px"}}>Actor</p>
              </div>
            ))
          ) : (
            <p>{translate('No cast available')}</p>
          )}
        </div>
      </div>

      {/* Movie Crew */}
      <div className="movie-crew">
        <h3><b>{translate('Crew')}</b></h3>
        <div className="crew-list">
          {crewDetails.length > 0 ? (
            crewDetails.map(crew => (
              <div key={crew.id} className="crew-item">
                <img src={crew.crew_image} alt={crew.crew_name} />
                <p>{crew.crew_name} </p>
                <p style={{fontSize:"10px"}}>{crew.designation}</p>
              </div>
            ))
          ) : (
            <p>{translate('No crew available')}</p>
          )}
        </div>
      </div>

      {isModalOpen && (
  <div className="modal">
  <div className="modal-content">
    <h2>{translate('Select Language and format')}</h2>

    {/* Language option */}
    <p className="language-option">{movieDetails.language}</p>

    {/* Movie format options */}
    <div className="movie-format-container">
          <p
            className={`movie_format ${selectedFormat === '2D' ? 'selected' : ''}`}
            onClick={() => handleFormatClick('2D')}
          >
            2D
          </p>
          <p
            className={`movie_format ${selectedFormat === '3D' ? 'selected' : ''}`}
            onClick={() => handleFormatClick('3D')}
          >
            3D
          </p>
        </div>

    {/* Buttons */}
    <button onClick={handleBookTickets}>{translate('Book')}</button>
    <button onClick={() => setIsModalOpen(false)}>{translate('Close')}</button>
  </div>
</div>

)}

    </div>
  );
};

export default Product_detail;
