import React, { Component } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Prductslide1 from './Prductslide1';
import { withRouter } from "./withRouter";
import Myorderdetails from './Myorderdetails';

class Myorders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      user_id: localStorage.getItem('admin_id'),
    };
  }

  

  render() {
    return (
      <div className="box">
        <Header_home />
        <div className='myorders'>
          <div className='my_ord'>
            <Myorderdetails
              orderStatus={this.state.orderStatus}
              orderTime={this.state.orderTime}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Myorders);
