import React from 'react';
import './LocationPopup.css';

const LocationPopup = ({ isOpen, onClose, onSelectLocation }) => {
  if (!isOpen) return null;

  const locations = ['Coimbatore', 'Chennai', 'Bangalore', 'Mumbai'];

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Select Location</h2>
        <ul>
          {locations.map(location => (
            <li key={location} onClick={() => onSelectLocation(location)}>
              {location}
            </li>
          ))}
        </ul>
        <button className="close-btn1" onClick={onClose}>
          Close
        </button>
      </div>
    </div> 
  );
}; 

export default LocationPopup;
