import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "./Product_detail3.css"; // CSS for the design
import { useTranslation } from './TranslationContext';
// import seatIcon from './seat_icon.png';  // Assuming you'll use the proper image for seat
// import standingIcon from './standing_icon.png';  // Assuming you'll use the proper image for standing

const Product_detail2 = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [eventDetails1, setEventDetails1] = useState([]); // Store event details
  const [selectedSeats, setSelectedSeats] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // For seat selection popup
  const [totalPrice, setTotalPrice] = useState(0);
  const [transactionId, setTransactionId] = useState(''); // To store transaction ID
  const event_type = localStorage.getItem('event_type');

  const { translate } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const seat_id = localStorage.getItem('seat_id');
       
        const hall_id = localStorage.getItem('hall_id');

        const response = await axios.get(`${api_url}/getseatconts/${seat_id}`);
        setEventDetails(response.data.hallseats);

        const response1 = await axios.get(`${api_url}/getHalls1/${hall_id}`);
        setEventDetails1(response1.data.hall);

       
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    setIsModalOpen(true);
    fetchEventDetails();
  }, []);

  if (!eventDetails) {
    return <div>{translate('Loading...')}</div>;
  }

  const handleSeatSelection = (numSeats, pricePerSeat) => {
    setSelectedSeats(numSeats);
    setTotalPrice(numSeats * pricePerSeat);
    setIsModalOpen(false); // Close the modal after selection
  };

  const openSeatSelectionModal = () => {
    setIsModalOpen(true);
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  };

  const handleBookNow = async () => {

    const user_id =  localStorage.getItem("admin_id");
    const event_id = localStorage.getItem('event_id');
    const hall_id = localStorage.getItem('hall_id');
    const api_url = localStorage.getItem('api_url');
    const seat_id = localStorage.getItem('seat_id');


    try {
      // Step 1: Call the paymentorder API to create an order and transaction
      const paymentResponse = await axios.post(`${api_url}/paymentorder`, {
        tot_amt: totalPrice,
        transactionid: transactionId
      });
      const { transactionid, orderId } = paymentResponse.data;
      setTransactionId(transactionid);

      // Step 2: Initiate Razorpay payment
      const options = {
        key: 'rzp_test_JFlMQ0tOgzb3j8', // Example Razorpay test key
        amount: totalPrice * 100, // amount in paise
        currency: 'INR',
        name: 'Movie Ticket',
        description: 'Movie Ticket Transactions',
        image: 'https://example.com/your_logo',
        order_id: orderId,
        handler: async (response) => {
          const { razorpay_payment_id } = response;

          // Step 3: Call the savetransactions API after payment success
          const saveTransactionResponse = await axios.post(`${api_url}/savetrancations_events`, {
            user_id,
            payment_success_id: razorpay_payment_id,
            status: 'booked',
            transactionid: razorpay_payment_id,
            total_amt: totalPrice
          });

          if (saveTransactionResponse.data) {
            // Step 4: Call the bookings_confirm API after transaction is saved
            // const seat_ids = selectedSeats.map(seat => seat.seatId).join(',');

            const confirmBookingResponse = await axios.post(`${api_url}/bookings_confirm_events`, {
              event_id:event_id,
              hall_id:hall_id,
              seat_id:seat_id,
              seat_numbers:selectedSeats,
              total_price:totalPrice,
              transaction_id:razorpay_payment_id,
              user_id
            });

            if (confirmBookingResponse.data) {
              alert('Booking confirmed successfully!');
              
              navigate("/Myorders", { replace: true });
            } else {
              alert('Failed to confirm booking.');
            }
          } else {
            alert('Failed to save transaction.');
          }
        },
        prefill: {
          name: "Harsanth", // Example prefill data
          email: "harsanthjack@gmail.com",
          contact: "8667040195"
        },
        theme: {
          color: '#3399cc'
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error during booking process:', error);
      alert('An error occurred while processing your booking.');
    }
  };

  return (
    <div>
      {/* Event Auditorium Background */}
      <div>
       
 <img
 className="event-banner"
 style={{ backgroundSize: 'cover',width:"100%" }}
 src={eventDetails1.image} />
        
     
      
      </div>

      {/* Seat Selection Modal */} 
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4 style={{textAlign:"center"}}>{translate('Select the number of Tickets')}</h4>
              
            <div className="seat-selection">
              {[...Array(10).keys()].map((num) => (
                <button
                  key={num + 1}
                  onClick={() => handleSeatSelection(num + 1, eventDetails.price)}
                  className="seat-button"
                >
                  {num + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Bottom Section: Total Price and Book Tickets Button */}
      {selectedSeats > 0 && (
        <div className="bottom-bar">
          <p>{translate('Total Amount')}: ₹{totalPrice}</p>
          <button className="book-button" onClick={handleBookNow}>
            {translate('Book Tickets')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Product_detail2;
