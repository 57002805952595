import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from './TranslationContext'; // Import useTranslation

import './style.css';

const Footer = () => {
  const { translate } = useTranslation();
  const [apiUrl] = useState(localStorage.getItem('api_url'));
  const [userId] = useState(localStorage.getItem('admin_id'));
  const [movies, setMovies] = useState([]); // State to store movies

  useEffect(() => {
    // Dynamically load the Tawk.to script
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement('script');
      var s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66bf5e4c0cca4f8a7a76cc86/1i5dognau';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  // Fetch movie names
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get(`${apiUrl}/getmovies`); // Replace with your API endpoint
        setMovies(response.data.moviedetails); // Assuming response.data.movies contains the array of movie names
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, [apiUrl]);

  return (
    <div className="footer" style={{width:"99%"}}>
      <div className="row" style={{backgroundColor:"#404043",marginRight:"0px"}}>
        <div className="col-md-4">
        <img className="" style={{width:"35%"}} alt="Logo" title="Logo" src={require('./img/cus.png')}  />

        </div>
        <div className="col-md-4">
        <img className="" style={{width:"50%"}} alt="Logo" title="Logo" src={require('./img/ress.png')}  />
        </div>
        <div className="col-md-4">
        <img className="" style={{width:"47%"}} alt="Logo" title="Logo" src={require('./img/sub.png')}  />
        </div>
      </div>

      <div style={{backgroundColor:"#333338"}}>
         {/* Display Movies */}
         <div style={{textAlign:"left",padding:"20px"}}>
         <p style={{ color: "#A5A5A5" }}>Movies Now</p>
              <div style={{ color: "#737F74", padding: "10px" }}>
              {movies.length > 0 ? (
              <p>
              {movies.map((movie, index) => (
              <span style={{display:"flex"}}  key={movie.id}>
              <p  style={{display:"flex"}}  to={`/movie/${movie.id}`}>{movie.movie_name} |</p>
              </span>
              ))}
              </p>
              ) : (
              <p>{translate('No movies available')}</p>
              )}
         </div>
             
</div>

      </div>
    </div>
  );
};

export default Footer;
