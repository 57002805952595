import React,{useState} from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import axios from 'axios';
import Prductslide1 from './Prductslide1';
import HelpSupport from './HelpSupport';




 
  
 

class Help extends React.Component{


  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
      user_id:localStorage.getItem('admin_id'),
        data:[],product:{},
        product_detail:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',email1:'',password:''
        
    };     

    // console.log(this.state.product_id)
    
  }


  componentDidMount() {    
    // this.product_detailapi(); 
}
  

      render(){
        return  <div className="box"> 
                   { <Header_home /> }       
                            
                                 <HelpSupport />
                          <br></br>
                          <br></br>

                   { <Footer /> }
                </div>
      }
}

export default Help;