import React from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Product_detail2 from './Product_detail2';

class Seatseaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      movie_id: localStorage.getItem('movie_id'),
    };
  }

  componentDidMount() {
    // You can add any necessary API calls or logic here.
  }

  render() {
    return (
      <div className="">
        <Header_home />
        
          <Product_detail2 />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Seatseaction;
