import React, { useState, useEffect } from "react";
import './Ordercard.css'; // Import your CSS file for styling
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';  // Correct import for react-qr-code


const Mynotifi = ({ orderStatus, orderTime }) => {
  const [cards, setCards] = useState([]);
 
  const navigate = useNavigate();


  useEffect(() => {
    const fetchCards = async () => {
      try { 
        const user_id = localStorage.getItem('admin_id');
            const api_url = localStorage.getItem('api_url');
            const response = await axios.get(api_url + '/getProfile/'+user_id);
            setCards(response.data);
      } catch (error) {
        console.error("Error fetching card data:", error);
       
      }
    };

    fetchCards();
  }); // Include orderStatus, orderTime, and searchInput in the dependency array

 


  return (
    <div>
      <p className='my_or1'><b>Your Notifications</b></p>
      <p></p>
      <hr></hr>
      {cards.notification && cards.notification.map((notifi) => (
                            <div key={notifi._id} className='address_list'>
                            <div className='notification_list'>
                                    <div className='notification'>
                                    <img className='noti_img' src={notifi.image} />
                                    </div>
                                    <div className='noti_text'>
                                        <p className='n_text'>{notifi.body} {notifi.extra_info}</p>
                                        {/* <p className='n_date'>14 Feb 2024</p> */}
                                    </div>
                                </div>    
                            </div>
                           
                            ))}
      <br></br>
      <p> </p>
    </div>
  );
};

export default Mynotifi;
