import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./Prductslide.css";
import Product_rating from './Product_rating';

const Prductslide3 = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const handleDivClick = (id) => {
    localStorage.setItem("event_id", id);
    localStorage.setItem("event_type", "event");
    navigate("/Events", { replace: true });
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(api_url + '/getEvents1');
        setCards(response.data.events);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

  const [startIndex, setStartIndex] = useState(0);

  const onNextClick = () => {
    const newIndex = startIndex + 5;
    if (newIndex < cards.length) {
      setStartIndex(newIndex);
    }
  };

  const onPrevClick = () => {
    const newIndex = startIndex - 5;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  // Calculate the container width
  const visibleCards = cards.slice(startIndex, startIndex + 5);
  const containerWidth = visibleCards.length === 5 ? '100%' : '75%';

  return (
    <div className="slideshows">
      <div className="card-container" style={{ width: containerWidth }}>
        {visibleCards.map((card) => (
          <div key={card.id} className="card">
            {card.event_image ? (
              <img
                className='med_img'
                src={card.event_image}
                alt={card.event_name}
                onClick={() => handleDivClick(card.id)}
              />
            ) : (
              <div className="placeholder-image" onClick={() => handleDivClick(card.id)}>
                No Image Available
              </div>
            )}
            <div className="medicine-details">
              <p  style={{textAlign:"left"}} className=""><b>{card.event_name}</b></p>

              
            </div>
          </div>
        ))}
      </div>
      <br />
      {visibleCards.length > 0 && (
  <div className="controls">
    <button
      className="bu_n"
      onClick={onPrevClick}
      disabled={startIndex === 0} // Disable "Prev" if we're at the beginning
    >
      &lt;
    </button>
    <button
      className="bu_n"
      onClick={onNextClick}
      disabled={startIndex + visibleCards.length >= cards.length} // Disable "Next" if we're at the end
    >
      &gt;
    </button>
  </div>
)}
     
    </div>
  );
};

export default Prductslide3;
