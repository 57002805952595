import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';

import axios from 'axios';
import ContainerSelection from './ContainerSelection';

import { withRouter } from './withRouter';
import { useNavigate } from 'react-router-dom';
class Orderplace extends React.Component {

  constructor(props) {
    super(props);
    this.backtohome = this.backtohome.bind(this);
    this.state = {
        
      api_url: localStorage.getItem('api_url'),
      category_id: localStorage.getItem('category_id'),
      data: [],
      product: {},
      product_detail: [],
      category_detail: [],
      subcategory_detail: [],
      prescription: 'no',
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      pic: '',
      fileName: '',
      email: '',
      email1: '',
      password: '',
      loading: true, // Initialize loading state here
    };
  }



  componentDidMount() {
  }

  backtohome(){
    this.props.navigate('/Home');
  }

  render() {
    return (

      
      <div className="">
        {<Header_home />}
        
          <ContainerSelection />
        {<Footer />}
      </div>
    );
  }
}

export default withRouter(Orderplace);
