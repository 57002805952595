import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './Showtimes.css'; // Create this file for custom styles

const Showtimes = () => {
  const [showtimes, setShowtimes] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0); // State for selected date
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    const fetchShowtimes = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const movieId = localStorage.getItem('movie_id');

        const response = await axios.get(`${api_url}/getmovie_timeslots/${movieId}`);
        setShowtimes(response.data.showtimes); // Store all dates
      } catch (error) {
        console.error('Error fetching showtimes:', error);
      }
    };

    fetchShowtimes();
  }, []);  // Empty dependency array to ensure it runs once

  // Handle the click event to store values in localStorage and navigate
  const handleTimeslotClick = (theatre_id, screen_id, date, timeslot_id) => {
    const movie_id = localStorage.getItem('movie_id');  // Get movie_id from localStorage

    // Set values in localStorage
    localStorage.setItem('theatre_id', theatre_id);
    localStorage.setItem('screen_id', screen_id);
    localStorage.setItem('date', date);
    localStorage.setItem('timeslot_id', timeslot_id);
    localStorage.setItem('movie_id', movie_id);

    // Navigate to the containers1 page
    navigate("/Orderplace", { replace: true });
  };

  return (
    <div className="showtimes-container">
      <div className="date-row">
        {showtimes.map((dateGroup, index) => (
          <button
            key={index}
            className={`date-button ${index === selectedDateIndex ? 'selected' : ''}`} // Highlight selected date
            onClick={() => setSelectedDateIndex(index)} // Change selected date
          >
            {dateGroup.movie_date}
          </button>
        ))}
      </div>

      <div className="theatre-container">
        {showtimes.length > 0 && showtimes[selectedDateIndex].theatres.map((theatre, theatreIndex) => (
          <div key={theatreIndex} className="theatre-row">
            <div className="theatre-details">
              <h4 className="theatre-name">{theatre.theatre_name}</h4> 
              {theatre.screens.map((screen, screenIndex) => (
                <div key={screenIndex} className="screen-details">
                  <h5 className="screen-name">{screen.screen_name}</h5>
                </div> 
              ))}
            </div>
            <div className="showtime-slots">
              {theatre.screens.map((screen) => (
                <div key={screen.screen_name} className="screen-showtimes">
                  {screen.showtimes.map((slot, slotIndex) => (
                    <button
                      key={slotIndex}
                      className="showtime-button"
                      onClick={() => handleTimeslotClick(slot.theatre_id, slot.screen_id, slot.date, slot.timeslot_id)} // Pass values to handler
                    >
                      {slot.show_time}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Showtimes;
