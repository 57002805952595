import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.rtl.css';
import Login from "./Login";
import Home from './Home';
import Movies from './Movies';
import Movieslist from './Movieslist';

import Events from './Events';
import Eventslist from './Eventslist';
import Eventslistsport from './Eventslistsport';

import Detail_page from './Detail_page';
import Addcart from './Addcart';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Likecart from './Likecart';
import Profile from './Profile';
import Popup from './Popup';
import Myorders from './Myorders';
import Trackorder from './Trackorder';
import Signup from './Signup';
import Orderplace from './Orderplace';
import DummyContent from './DummyContent';
import Gpt3Component from './Gpt3Component';
import useCustomBackNavigation from './useCustomBackNavigation';
import PrivateRoute from './PrivateRoute';
import { TranslationProvider } from './TranslationContext';
import LocationComponent from './LocationComponent';
import MapComponent from './MapComponent';
import ContainerSelection from "./ContainerSelection"
import LocationPopup from "./LocationPopup"
import Mynotifications from './Mynotifications';
import Help from './Help';
import Seatseaction from './Seatseaction';
import Seatconfirm from './Seatconfirm';


const App = () => {
  // useCustomBackNavigation();

  return (
    <div className="App">
       <TranslationProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Home" element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/Movies" element={
            <PrivateRoute>
              <Movies />
            </PrivateRoute>
          } />
           <Route path="/Movieslist" element={
            <PrivateRoute>
              <Movieslist />
            </PrivateRoute>
          } />
          <Route path="/Detail_page" element={
            <PrivateRoute>
              <Detail_page />
            </PrivateRoute>
          } />
          <Route path="/Addcart" element={
            <PrivateRoute>
              <Addcart />
            </PrivateRoute>
          } />
          <Route path="/Likecart" element={
            <PrivateRoute>
              <Likecart />
            </PrivateRoute>
          } />
          <Route path="/Profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          <Route path="/Popup" element={
            <PrivateRoute>
              <Popup />
            </PrivateRoute>
          } />
          <Route path="/Myorders" element={
            <PrivateRoute>
              <Myorders />
            </PrivateRoute>
          } />
          <Route path="/Trackorder" element={
            <PrivateRoute>
              <Trackorder />
            </PrivateRoute>
          } />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Orderplace" element={
            <PrivateRoute>
              <Orderplace />
            </PrivateRoute>
          } />
          <Route path="/Gpt3Component" element={
            <PrivateRoute>
              <Gpt3Component />
            </PrivateRoute>
          } />

<Route path="/LocationComponent" element={
            <PrivateRoute>
              <LocationComponent />
            </PrivateRoute>
          } />
          <Route path="/MapComponent" element={
            <PrivateRoute>
              <MapComponent />
            </PrivateRoute>
          } />
        <Route path="/ContainerSelection" element={
        <PrivateRoute>
        <ContainerSelection />
        </PrivateRoute>
        } />
         <Route path="/LocationPopup" element={
        <PrivateRoute>
        <LocationPopup />
        </PrivateRoute>
        } />
 <Route path="/Mynotifications" element={
        <PrivateRoute>
        <Mynotifications />
        </PrivateRoute>
        } />

<Route path="/Help" element={
        <PrivateRoute>
        <Help />
        </PrivateRoute>
        } />
        <Route path="/Events" element={
        <PrivateRoute>
        <Events />
        </PrivateRoute>
        } />
         <Route path="/Eventslist" element={
        <PrivateRoute>
        <Eventslist />
        </PrivateRoute>
        } />
        <Route path="/Eventslistsport" element={
        <PrivateRoute>
        <Eventslistsport />
        </PrivateRoute>
        } />
         <Route path="/Seatseaction" element={
        <PrivateRoute>
        <Seatseaction />
        </PrivateRoute>
        } />

<Route path="/Seatconfirm" element={
        <PrivateRoute>
        <Seatconfirm />
        </PrivateRoute>
        } />
          <Route path="/support-center" element={<DummyContent title="Support Center" />} />
          <Route path="/policy" element={<DummyContent title="Policy" />} />
          <Route path="/terms-conditions" element={<DummyContent title="Term & Conditions" />} />
          <Route path="/shipping" element={<DummyContent title="Shipping" />} />
          <Route path="/return" element={<DummyContent title="Return" />} />
          <Route path="/register-seller" element={<DummyContent title="Register A Seller" form />} />
          <Route path="/faqs" element={<DummyContent title="FAQs" />} />
          <Route path="/about-us" element={<DummyContent title="About Us" />} />
          <Route path="/career" element={<DummyContent title="Career" />} />
          <Route path="/contact-us" element={<DummyContent title="Contact Us" form />} />
          <Route path="/affiliate" element={<DummyContent title="Affiliate" />} />
          <Route path="/sell-on-market-place" element={<DummyContent title="Sell on Market Place" />} />
          <Route path="/advertises-on-market-place" element={<DummyContent title="Advertises on Market Place" />} />
        </Routes>
      </Router>
      </TranslationProvider>
    </div>
  );
}

export default App;
